import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

export const TermsOfService = () => {
  return (
    <Container style={{ marginTop: '100px', marginBottom: '100px' }}>
      <Box display="flex" justifyContent="center" pt={8}>
        <Typography variant="h3" color="secondary" gutterBottom>
          Terms of Service
        </Typography>
      </Box>
      <div
        id="otnotice-6aa6fcde-a282-4d0b-8660-7b5fe4ee08d7"
        className="otnotice"
      />
    </Container>
  )
}

export default TermsOfService
