import React from 'react'
import { LayoutV2 } from '../../components/common'
import { Helmet } from 'react-helmet'
import { TermsOfService } from '../../components/TermsOfService'
import { withPrefix } from 'gatsby'

const TermsOfServicePage = ({ data = {} }) => {
  return (
    <LayoutV2 title="PGA.Coach">
      <Helmet>
        <script
          src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
          type="text/javascript"
          charset="UTF-8"
          id="otprivacy-notice-script"
        >
          {`settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"`}
        </script>
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <TermsOfService />
    </LayoutV2>
  )
}

export default TermsOfServicePage
